import React from 'react'

function Services() {
    return (
        <section className='ServicesSection BodyFont'>
            <h2 className='ServicesTitle HeaderFont'>Services</h2>
            <p>
                <strong>Individuals:</strong> Regardless of your income complexity, or services, Weber Tax & Accounting will tailor our services to meet your needs. We also provide thorough projection and planning services to make the most efficient choices for your future.
            </p>
            <p>
                <strong>Trusts and Estates:</strong>  We prepare fiduciary (Form 1041) returns for trusts and estates.
            </p>
            <p>    
                <strong>Businesses:</strong>  We prepare S Corporation and Partnership tax returns for small and mid-sized businesses. We assist with tax projections and tax planning.
            </p>
            <p>
                <strong>Gift Tax Returns:</strong> We provide gift tax preparation and estate planning needs to future-proof you and your family's wealth.
            </p>
        </section>
    )
}

export default Services